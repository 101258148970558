import { message, notification } from "antd";
import { emitter } from "../../emitter";
import { useEffect } from "react";

type NotificationType = "success" | "info" | "warning" | "error";

type TNotification = {
    error?: any;
    title: string;
    description?: string;
    type: NotificationType;
};
type TMessage = {
    content: string;
    type: NotificationType;
};
export const useNotification = () => {
    const [apiNotification, contextHolderNotification] = notification.useNotification();

    const handleEventNotification = (value: TNotification) => {
        apiNotification[value.type]({
            message: value.title,
            description: value.description,
            duration: 3,
        });
    };

    useEffect(() => {
        emitter.on("notification-emit", handleEventNotification);
        return () => {
            emitter.removeListener("notification-emit", handleEventNotification);
        };
    }, []);

    return {
        contextHolderNotification,
    };
};
export const useMessage = () => {
    const [apiMessage, contextHolderMessage] = message.useMessage();

    const handleEvenMessage = (value: TMessage) => {
        apiMessage.open({
            type: value.type,
            content: value.content,
            duration: 3,
        });
    };

    useEffect(() => {
        emitter.on("message-emit", handleEvenMessage);
        return () => {
            emitter.removeListener("message-emit", handleEvenMessage);
        };
    }, []);

    return {
        contextHolderMessage,
    };
};

export const notificationEmit = (value: TNotification) => {
    emitter.emit("notification-emit", value);
};
export const messageEmit = (value: TMessage) => {
    emitter.emit("message-emit", value);
};
