import { FlashAttempts, flashAttemptsModel } from "@entities/FlashAttempts";
import { Button, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPreparedData } from "../lib";
import { useFlashAttemptsTable } from "../model";
import FlashAttemptTime from "./FlashAttemptTime";
import FlashAttemptsActions from "./FlashAttemptsActions";
import FlashAttemptsModal from "./FlashAttemptsModal";

const FlashAttemptsTable = () => {
    const [changed, setChanged] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const isLoading = useSelector(flashAttemptsModel.selectFlashAttemptsIsLoading);
    const { onSelectChange, onClickDeleteManyFlashAttempts } = useFlashAttemptsTable(
        selectedIds,
        setSelectedIds,
        setChanged
    );
    const { loadFlashAttempts, flashAttempts } = flashAttemptsModel.useLoadFlashAttempts(true);

    useEffect(() => {
        loadFlashAttempts();
        setChanged(false);
    }, [changed]);

    const preparedData = getPreparedData(flashAttempts);

    const columns: TableColumnsType<TTableFlashAttemptDataType> = [
        { title: "№", dataIndex: "id", key: "id" },
        { title: "Ответственный", dataIndex: "userName", key: "userName" },
        { title: "MAC-адрес устройства", dataIndex: "mac", key: "mac" },
        {
            title: "Дата прошивки",
            dataIndex: "created_at",
            key: "created_at",
            sorter: (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
            render: (text: string) => <FlashAttemptTime time={text} />,
        },
        {
            title: "Дата изменения",
            dataIndex: "updated_at",
            key: "updated_at",
            sorter: (a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at),
            render: (text: string) => <FlashAttemptTime time={text} />,
        },
        { title: "Тип устройства", dataIndex: "type", key: "type" },
        { title: "Подтип устройства", dataIndex: "subtype", key: "subtype" },
        {
            title: "Версия прошивки",
            dataIndex: "version",
            key: "version",
        },
        { title: "Статус прошивки", dataIndex: "status", key: "status" },
        {
            title: "Действия",
            dataIndex: "",
            key: "",
            render: (text: TFlashAttemptData) => (
                <FlashAttemptsActions
                    selectedIds={selectedIds}
                    setChanged={setChanged}
                    row={text}
                />
            ),
        },
    ];

    return (
        <>
            <FlashAttemptsModal setChanged={setChanged} />
            <Button
                disabled={selectedIds.length < 1}
                style={{ marginBottom: 8 }}
                onClick={onClickDeleteManyFlashAttempts}
                danger
            >
                Удалить выбранные элементы
            </Button>
            <FlashAttempts
                data={preparedData}
                columns={columns}
                isLoading={isLoading}
                selectedIds={selectedIds}
                onSelectChange={onSelectChange}
            />
        </>
    );
};

export default FlashAttemptsTable;
