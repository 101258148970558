import { Table } from "antd";
import { TableProps } from "antd/lib/table";

interface IDeviceVersionItemTable {
    data: TTableDataGroupedSubType[];
    columns: TableProps<TTableDataGroupedSubType>["columns"];
}

const DeviceVersionItemTable = ({ data, columns }: IDeviceVersionItemTable) => {
    const height = window.innerHeight;

    return (
        <Table
            scroll={{ y: height - 280 }}
            columns={columns}
            dataSource={data}
            pagination={false}
        />
    );
};

export default DeviceVersionItemTable;
