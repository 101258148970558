import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useFirmwareCommand } from "../model";

interface IFirmwareButton extends IFirmwareButtonFeature {}

const FirmwareButton = ({ transport, esptool }: IFirmwareButton) => {
    const { firmwareCommand, isActive } = useFirmwareCommand(transport, esptool);
    const subType = useSelector(firmwareDeviceModel.selectCurrentDeviceSubType);
    return (
        <Button
            loading={isActive}
            disabled={!subType}
            onClick={firmwareCommand}
            type="primary"
            style={{ marginBottom: 8 }}
        >
            Прошить
        </Button>
    );
};

export default FirmwareButton;
