import { useDispatch } from "react-redux";
import { useEspLoaderTerminal } from "./UseEspLoaderTerminal";
import { MAX_BAUDRATE } from "./constants";
import { ESPLoader, Transport } from "./esptool-js/lib";
import { firmwareDeviceModel } from "@entities/FirmwareDevice";

export const useEsptoolErase = () => {
    const espLoaderTerminal = useEspLoaderTerminal();
    const dispatch = useDispatch();

    const startEspErase = async (transport: Transport) => {
        const flashOptions = {
            transport: transport,
            baudrate: MAX_BAUDRATE,
            terminal: espLoaderTerminal,
        } as any;

        // console.log("getINFO", transport.getInfo());
        // const esptool = await getEsptool(flashOptions);
        // console.log(esptool);
        const esptool = new ESPLoader(flashOptions);
        try {
            console.log("START");
            const { chip, mac } = await esptool.main();
            dispatch(firmwareDeviceModel.setMac(mac.toUpperCase()));

            await esptool.eraseFlash();
            await esptool.transport.disconnect();
        } catch (error) {
            //await esptool.hardReset();
            console.log("ERROR", error);
            throw error;
        }
    };
    return {
        startEspErase,
    };
};
