import { flashAttemptsModel } from "@entities/FlashAttempts";
import { deleteFlashAttempt } from "@entities/FlashAttempts/api";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useDispatch } from "react-redux";

export const useFlashAttemptsActions = (
    row: TFlashAttemptData,
    setChanged: (item: boolean) => void
) => {
    const dispatch = useDispatch();

    const onEditRowClick = () => {
        dispatch(flashAttemptsModel.setEditingFlashAttempt({ ...row, type: row.device_type.id }));
    };

    const deleteRow = (id: number) => {
        deleteFlashAttempt(id)
            .then(() => {
                notificationEmit({
                    title: "Изменение истории прошивки",
                    description: `История прошвки устройства №${id} удалена`,
                    type: "info",
                });
                setChanged(true);
            })
            .catch((e) =>
                notificationEmit({
                    title: "Изменение истории прошивки",
                    description: `Не удалось удалить историю прошивки устройства №${id} удалена`,
                    type: "error",
                })
            );
    };
    const onDeleteRowClick = () => {
        deleteRow(row.id);
    };

    return {
        onDeleteRowClick,
        onEditRowClick,
    };
};
