import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFlashAttemptsHistory } from "../api";

export const loadFlashAttemptsHistoryThunk = createAsyncThunk(
    "flashAttemptsSlice/loadFilteredData",
    async (params: TParams, { rejectWithValue }) => {
        try {
            const response = await getFlashAttemptsHistory(params);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
