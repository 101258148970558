import { ESPLoader, Transport } from "@shared/esptool/esptool-js/lib";

export const useSerial = (
    transport: React.MutableRefObject<Transport | undefined>,
    esptool: React.MutableRefObject<ESPLoader | undefined>
) => {
    const requestSerialPort = async () => {
        if (transport.current === undefined) {
            const serial = await navigator.serial.requestPort();
            transport.current = new Transport(serial, true, false);
        }

        return transport.current;
    };
    const closeSerial = async () => {
        try {
            await transport.current?.disconnect();
        } catch {}
        transport.current = undefined;
    };

    const getEsptool = async (flashOptions: any) => {
        esptool.current = new ESPLoader({ ...flashOptions });
        await esptool.current.main();
        return esptool.current;
    };

    return {
        requestSerialPort,
        closeSerial,
        getEsptool,
    };
};
