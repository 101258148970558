import { flashAttemptsApi } from "@entities/FlashAttempts";

export const useFlashAttemptsTable = (
    selectedIds: React.Key[],
    setSelectedIds: (item: React.Key[]) => void,
    setChanged: (item: boolean) => void
) => {
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedIds(newSelectedRowKeys);
    };

    const onClickDeleteManyFlashAttempts = () => {
        flashAttemptsApi.deleteManyFlashAttempts(selectedIds).catch((e) => {});
        setChanged(true);
        setSelectedIds([]);
    };

    return {
        onSelectChange,
        onClickDeleteManyFlashAttempts,
    };
};
