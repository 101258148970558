import { useSerial } from "@shared/serial";
import { Button } from "antd";

interface IFirmwareChangePortButton extends IFirmwareButtonFeature {}

const FirmwareChangePortButton = ({ esptool, transport }: IFirmwareChangePortButton) => {
    const { closeSerial, requestSerialPort } = useSerial(transport, esptool);
    return (
        <Button
            onClick={async () => {
                await closeSerial();
                await requestSerialPort();
            }}
        >
            Сменить порт
        </Button>
    );
};

export default FirmwareChangePortButton;
