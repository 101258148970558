import { useRef, useState } from "react";

export const useFilter = <T>(initialState: T, request: (values: T, reset: boolean) => void) => {
    const [values, setValues] = useState<T>(initialState);

    const timer = useRef<any>();
    const onChange = (name: string, value: any, skipRequest?: boolean) => {
        const newValues: T = { ...values, [name]: value };
        if (newValues)
            setValues({
                ...values,
                [name]: value,
            });

        if (!skipRequest) {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                request(newValues, false);
            }, 400);
        }
    };

    const onReset = () => {
        request({} as T, true);
        setValues(initialState);
    };

    const onSubmit = () => {
        request(values, false);
    };

    return {
        onChange,
        onReset,
        setValues,
        values,
        onSubmit,
    };
};
