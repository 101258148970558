import { useSerial } from "@shared/serial";
import { Button } from "antd";

interface IFirmwareStopButton extends IFirmwareButtonFeature {}

const FirmwareStopButton = ({ esptool, transport }: IFirmwareStopButton) => {
    const { closeSerial } = useSerial(transport, esptool);

    return (
        <Button onClick={async () => await closeSerial()} style={{ marginBottom: 8 }}>
            Остановить прошивку
        </Button>
    );
};

export default FirmwareStopButton;
