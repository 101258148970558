import { DeleteOutlined } from "@ant-design/icons";
import { useDeviceSubTypeVersions } from "../../model";
import "./DeviceSubTypeVersions.scss";
import { Popconfirm } from "antd";

const DeviceSubTypeVersions = ({ value }: { value: TTableDataGroupedSubType }) => {
    const { deleteVersion } = useDeviceSubTypeVersions();
    if (value.files.length > 1)
        return (
            <ul className="files-list" key={value.id}>
                {value.files.map((file, index) => {
                    const filenameWithoutExt = file.filename.split(".bin")[0];
                    const deleteClick = () => deleteVersion(filenameWithoutExt);
                    return (
                        <li key={index} className="files-list__item">
                            <span>
                                {index + 1}. {file.filename}
                            </span>
                            <Popconfirm
                                title="Удалить версию?"
                                okText="Удалить"
                                onConfirm={deleteClick}
                                cancelText="Отмена"
                            >
                                <DeleteOutlined
                                    style={{
                                        color: "red",
                                        position: "absolute",
                                        right: 16,
                                        top: 3,
                                    }}
                                />
                            </Popconfirm>
                        </li>
                    );
                })}
            </ul>
        );
    else
        return (
            <div style={{ color: "gray", textAlign: "center", width: 150 }}>
                Файлы версии не найдены
            </div>
        );
};

export default DeviceSubTypeVersions;
