import { FormItem } from "@shared/ui/Form";
import { Input, Modal } from "antd";
import { names } from "../constants";
import { useDeviceVersionModal } from "../model";

const DeviceChangeVersionModal = () => {
    const { error, editingSubType, onClose, onOk, onChange } = useDeviceVersionModal();
    if (editingSubType)
        return (
            <Modal onOk={onOk} open={true} onCancel={onClose} title={"Обновление версии"}>
                <FormItem label="Название">
                    <Input disabled={true} value={names[editingSubType.device_type]} />
                </FormItem>
                <FormItem label="Подтип">
                    <Input disabled={true} value={editingSubType?.subtype} />
                </FormItem>
                <FormItem label="Версия">
                    <Input
                        value={editingSubType?.version}
                        onChange={(value) => onChange("version", value.target.value)}
                    />
                </FormItem>
            </Modal>
        );
    return <></>;
};

export default DeviceChangeVersionModal;
