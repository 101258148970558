import { devicesModel } from "@entities/Devices";
import { useDispatch, useSelector } from "react-redux";

export const useUploadAdditionalFilesModal = (
    setIsDisableUploadButton: (item: boolean) => void
) => {
    const uploadingAdditionalFiles = useSelector(devicesModel.selectEditingAdditionalFiles);
    const dispatch = useDispatch<AppDispatch>();

    const onClose = () => {
        setIsDisableUploadButton(false);
        dispatch(devicesModel.setEditingAdditionalFiles(null));
    };

    const onOk = () => {
        if (uploadingAdditionalFiles) {
            const addresses =
                Object.keys(uploadingAdditionalFiles.additional_files).length > 0
                    ? Object.keys(uploadingAdditionalFiles.additional_files).filter((key) => {
                          return key !== "type_id" && key !== "subtype_name";
                      })
                    : null;
            const formData = new FormData();
            Object.entries(uploadingAdditionalFiles.additional_files).forEach(([key, value]) => {
                formData.append(key, value);
            });

            dispatch(devicesModel.updateAdditionalFilesThunk({ formData, addresses })).then(() =>
                onClose()
            );
        }
    };

    return { onClose, onOk };
};
