import { FormItem } from "@shared/ui/Form";
import { Select } from "antd";
import { DefaultOptionType, SelectValue } from "antd/es/select";
import "./FirmwareDeviceSelectItem.scss";

interface IFirmwareDeviceSelectItem {
    value: SelectValue;
    options?: DefaultOptionType[];
    label: string;
    placeholder?: string;
    disabled: boolean;
    notFoundContent?: React.ReactNode;
    onSelect?: (value: any, item: DefaultOptionType) => void;
}

const FirmwareDeviceSelectItem = ({
    value,
    options,
    label,
    placeholder,
    disabled,
    notFoundContent,
    onSelect,
}: IFirmwareDeviceSelectItem) => {
    return (
        <FormItem label={label}>
            <Select
                className="firmware-select-item"
                placeholder={placeholder ? placeholder : ""}
                value={value}
                options={options}
                notFoundContent={notFoundContent}
                onSelect={onSelect}
                disabled={disabled}
            />
        </FormItem>
    );
};

export default FirmwareDeviceSelectItem;
