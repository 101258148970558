import { FirmwareDeviceSelectItem } from "@entities/FirmwareDevice";
import { NotFoundContent } from "@shared/ui/NotFoundContent";
import { DefaultOptionType } from "antd/es/select";
import { useFirmwareDeviceType } from "../model";
import "./FirmwareDeviceType.scss";

interface IFirmwareDeviceType {
    setDeviceSubTypeOptions: (item: DefaultOptionType[]) => void;
}

const FirmwareDeviceType = ({ setDeviceSubTypeOptions }: IFirmwareDeviceType) => {
    const { deviceType, deviceTypeOptions, onSelectDeviceType } =
        useFirmwareDeviceType(setDeviceSubTypeOptions);
    const lastChoosenDeviceType = localStorage.getItem("deviceType");

    return (
        <FirmwareDeviceSelectItem
            label={"Тип устройства"}
            placeholder={"Выберите тип устройства"}
            value={lastChoosenDeviceType ?? deviceType ?? undefined}
            options={deviceTypeOptions}
            notFoundContent={<NotFoundContent condition={deviceTypeOptions.length < 1} />}
            onSelect={onSelectDeviceType}
            disabled={false}
        />
    );
};

export default FirmwareDeviceType;
