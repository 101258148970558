import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface IUser {
    authorizated: boolean;
    user: TUser | null;
    users: TUser[];
    access_token: string | null;
}

const initialState: IUser = {
    authorizated: false,
    users: [],
    access_token: null,
    user: null,
};
// action: PayloadAction<HomeLayout>
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.access_token = action.payload;
            localStorage.setItem("access_token", action.payload);
            cookies.set("accessToken", action.payload, { domain: ".vakio.ru" });
            cookies.set("accessToken", action.payload);
        },
        setUser: (state, action: PayloadAction<TUser>) => {
            state.user = action.payload;
            state.authorizated = true;
            state.user.isAdmin =
                action.payload.capabilities.includes("super_admin") ||
                action.payload.capabilities.includes("employee_admin");
            localStorage.setItem("user", JSON.stringify(action.payload));
            cookies.set("user", action.payload, { domain: ".vakio.ru" });
            cookies.set("user", action.payload);
        },
        // setAuthChekingState: (state, action: PayloadAction<boolean>) => {
        //     state.isAuthChecking = action.payload;
        // },
        signOut: (state) => {
            localStorage.removeItem("user");
            localStorage.removeItem("access_token");
            cookies.remove("accessToken", { domain: ".vakio.ru" });
            cookies.remove("accessToken");
            cookies.remove("user", { domain: ".vakio.ru" });
            cookies.remove("user");
            cookies.remove("refreshToken", { domain: ".vakio.ru" });
            cookies.remove("refreshToken");
            state.authorizated = false;
            state.access_token = null;
            state.user = null;
        },
    },
});

export const { setUser, setToken, signOut } = userSlice.actions;

export default userSlice.reducer;
