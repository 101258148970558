import { names } from "@features/Devices/constants";
import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import {
    deleteAdditionalFilesThunk,
    loadAdditionalFilesThunk,
    loadVersionsThunk,
    updateAdditionalFilesThunk,
    // updateAdditionalFilesThunk,
    updateVersionThunk,
} from "./DevicesThunk";

export interface IDeviceSlice {
    versions: TVersions;
    isLoading: boolean;
    groupedVersions: TGroupedVersion[];
    editingSubType: TTableDataGroupedSubType | null;
    error: any;
    additionalFiles: TAdditionalFiles;
    editingAdditionalFiles: TEditingAdditionalFiles | null;
}

const initialState: IDeviceSlice = {
    versions: [],
    groupedVersions: [],
    isLoading: false,
    editingSubType: null,
    error: null,
    additionalFiles: {},
    editingAdditionalFiles: null,
};

export const devicesSlice = createSlice({
    name: "devicesSlice",
    initialState,
    reducers: {
        setEditingSubType: (state, action: PayloadAction<TTableDataGroupedSubType | null>) => {
            state.editingSubType = action.payload;
        },
        setGroupedVersions: (state, action: PayloadAction<TGroupedVersion[]>) => {
            state.groupedVersions = action.payload;
        },
        updateGroupedVersions: (state, action: PayloadAction<TTableDataGroupedSubType>) => {
            const updatedSubType = action.payload;
            state.groupedVersions = state.groupedVersions.map((groupedVersion) => {
                if (groupedVersion.device_type_id === updatedSubType.device_type_id) {
                    groupedVersion.subtypes = groupedVersion.subtypes.map((subtype) => {
                        if (subtype.subtype === updatedSubType.subtype) {
                            return {
                                ...subtype,
                                version: updatedSubType.version,
                            };
                        }
                        return subtype;
                    });
                }
                return groupedVersion;
            });
        },
        setEditingAdditionalFiles: (
            state,
            action: PayloadAction<TEditingAdditionalFiles | null>
        ) => {
            state.editingAdditionalFiles = action.payload;
        },
    },
    extraReducers(builder) {
        loadVersionsBuilder(builder);
        loadAdditionalFiles(builder);
        deleteAdditionalFiles(builder);
        updateAdditionalFiles(builder);
        updateVersionBuilder(builder);
    },
});

const loadVersionsBuilder = (builder: ActionReducerMapBuilder<IDeviceSlice>) => {
    builder.addCase(loadVersionsThunk.fulfilled, (state, action) => {
        state.versions = action.payload;
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(loadVersionsThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(loadVersionsThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
        state.error = rejectedValue;
    });
};
const loadAdditionalFiles = (builder: ActionReducerMapBuilder<IDeviceSlice>) => {
    builder.addCase(loadAdditionalFilesThunk.fulfilled, (state, action) => {
        state.additionalFiles = action.payload;
        state.isLoading = false;
        state.error = null;
    });
    builder.addCase(loadAdditionalFilesThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(loadAdditionalFilesThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
        state.error = rejectedValue;
    });
};
const updateAdditionalFiles = (builder: ActionReducerMapBuilder<IDeviceSlice>) => {
    builder.addCase(updateAdditionalFilesThunk.fulfilled, (state, action) => {
        const updatedGroupType = action.payload.type_id;
        const updatedGroupSubTypeName = action.payload.subtype_name;
        const addresses = action.payload.addresses;
        console.log(addresses);
        state.groupedVersions = state.groupedVersions.map((group) => {
            if (group.device_type === updatedGroupType) {
                const newSubTypes = group.subtypes.map((subtype) => {
                    if (subtype.subtype === updatedGroupSubTypeName) {
                        return {
                            ...subtype,
                            additionalFiles: addresses
                                ? addresses.map((address) => `${address}.bin`)
                                : [],
                        };
                    }
                    return subtype;
                });
                return { ...group, subtypes: newSubTypes };
            }
            return group;
        });
        notificationEmit({ type: "success", title: "Файлы успешно загружены" });
    });
    builder.addCase(updateAdditionalFilesThunk.rejected, (state, rejectedValue) => {
        notificationEmit({
            type: "error",
            title: "Не удалось загрузить файлы",
            description: rejectedValue.error.message,
        });
        state.error = rejectedValue;
    });
};
const deleteAdditionalFiles = (builder: ActionReducerMapBuilder<IDeviceSlice>) => {
    builder.addCase(deleteAdditionalFilesThunk.fulfilled, (state, action) => {
        const updatedGroupType = action.payload.type_id;
        const updatedGroupSubTypeName = action.payload.subtype_name;
        state.groupedVersions = state.groupedVersions.map((group) => {
            if (group.device_type === updatedGroupType) {
                const newSubTypes = group.subtypes.map((subtype) => {
                    if (subtype.subtype === updatedGroupSubTypeName) {
                        return {
                            ...subtype,
                            additionalFiles: [],
                        };
                    }
                    return subtype;
                });
                return { ...group, subtypes: newSubTypes };
            }
            return group;
        });
        notificationEmit({ type: "success", title: "Файлы успешно удалены" });
    });
    builder.addCase(deleteAdditionalFilesThunk.rejected, (state, rejectedValue) => {
        notificationEmit({
            type: "error",
            title: "Не удалось удалить файлы",
            description: rejectedValue.error.message,
        });
        state.error = rejectedValue;
    });
};

const updateVersionBuilder = (builder: ActionReducerMapBuilder<IDeviceSlice>) => {
    builder.addCase(updateVersionThunk.fulfilled, (state, action) => {
        const updatedSubType = { ...action.payload };
        state.groupedVersions = state.groupedVersions.map((groupedVersion) => {
            if (groupedVersion.device_type_id === updatedSubType.device_type_id) {
                groupedVersion.subtypes = groupedVersion.subtypes.map((subtype) => {
                    if (subtype.subtype === updatedSubType.subtype) {
                        return {
                            ...subtype,
                            version: updatedSubType.version,
                        };
                    }
                    return subtype;
                });
            }
            return groupedVersion;
        });
        notificationEmit({
            title: "Изменение версии устройства",
            description: `Версия ${names[updatedSubType.device_type]}, ${updatedSubType.subtype} изменена  `,
            type: "info",
        });
        state.isLoading = false;
    });
    builder.addCase(updateVersionThunk.pending, (state, rejectedWithValue) => {
        state.isLoading = true;
    });
    builder.addCase(updateVersionThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
        notificationEmit({
            error: rejectedValue.error,
            title: "Ошибка",
            description: "Не удалось изменить версию",
            type: "error",
        });
        state.isLoading = false;
    });
};

export const {
    setEditingSubType,
    setGroupedVersions,
    updateGroupedVersions,
    setEditingAdditionalFiles,
} = devicesSlice.actions;

export default devicesSlice.reducer;
