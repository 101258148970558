import { devicesModel } from "@entities/Devices";
import { useDispatch } from "react-redux";

export const useDeviceTableActions = (value: TTableDataGroupedSubType) => {
    const dispatch = useDispatch();
    const onEditRowClick = () => {
        dispatch(devicesModel.setEditingSubType(value));
    };
    return {
        onEditRowClick,
    };
};
