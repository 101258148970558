import { $api } from "@shared/api/api";

export const getDevicesTypes = () =>
    $api.get<TResponse<TDeviceType[]>>("/api/firmware/device_types");
export const getDevicesSubTypes = (id: string) =>
    $api.post<any>("/api/firmware", {
        filter: {
            key: "device_type",
            value: id,
        },
        grouped: 1,
    });

export const getDeviceFirmwareURLs = (id: number) =>
    $api.get<TResponse<TVersionFiles>>(`/api/firmware/${id}`, {
        // responseType: "arraybuffer",
    });

export const getDeviceFirmwareFile = (route: string) =>
    $api.get<any>(`/api/firmware/download`, {
        responseType: "arraybuffer",
        // headers: {
        //     "Content-Type": "application/octet-stream",
        // },
        params: {
            path: route,
        },
    });
