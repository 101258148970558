import { UploadAdditionalFilesButton, UploadVersion } from "@features/Devices";
import "./DevicesMenuWidget.scss";

interface IDevicesMenuWidget {
    setIsOpen: (item: boolean) => void;
}

const DevicesMenuWidget = ({ setIsOpen }: IDevicesMenuWidget) => {
    return (
        <div className="devices-menubar">
            <div className="devices-menubar__item">
                <UploadVersion />
            </div>
            <div className="devices-menubar__item">
                {/* <UploadAdditionalFilesButton setIsOpen={setIsOpen} /> */}
            </div>
        </div>
    );
};

export default DevicesMenuWidget;
