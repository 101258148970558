import { theme } from "@shared/theme";
import { ConfigProvider } from "antd";
import ruRU from "antd/locale/ru_RU";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { Provider } from "react-redux";
import "./App.scss";
import { AppRouter } from "./router";
import { store } from "./store";
dayjs.locale("ru");
declare global {
    interface Window {
        Pusher: any;
        Echo: any;
    }
}
function App() {
    // window.Pusher = require("pusher-js");
    // window.Echo = new Echo({
    //   namespace: null,
    //   broadcaster: "pusher",
    //   key: "wsemployee",
    //   wsHost: process.env.REACT_APP_WS_API_URL,
    //   wsPort: process.env.REACT_APP_WS_PORT,
    //   wssPort: process.env.REACT_APP_WS_PORT,
    //   enabledTransports: ["ws"],
    //   disableStats: true,
    //   encrypted: false,
    //   //authEndpoint: "http://localhost:80/api/broadcasting",
    //   //authEndpoint: 'https://vakiolab.ru/api/broadcasting',
    //   forceTLS: process.env.REACT_APP_FORCED_TLS === "true" ? true : false,
    //   authorizer: (channel: any, options: any) => {
    //     return {
    //       authorize: (socketId: any, callback: any) => {
    //         callback(false, {});
    //       },
    //     };
    //   },
    //   auth: {
    //     headers: {
    //       Authorization: "Bearer ",
    //     },
    //   },
    // });

    return (
        <ConfigProvider locale={ruRU} theme={theme}>
            <Provider store={store}>
                {/* <Provider> */}
                {/* <h1>123</h1> */}
                <div className="App">
                    <AppRouter />
                </div>
            </Provider>
        </ConfigProvider>
    );
}

export default App;
