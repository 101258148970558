import { Layout, Menu } from "antd";
import "./Sidebar.scss";
import logo from "./logo.svg";
import { useSidebar } from "./model";
type SidebarProps = {
    menuItems: SideBarItems[];
    collapsed: boolean;
};

const Sidebar = ({ menuItems, collapsed }: SidebarProps) => {
    const {
        currentMenuItem,
        currentParentMenuItem,
        changeCurrentParrentOpenKeys,
        checkAccess,
        onSubmenuClick,
        onMenuClick,
    } = useSidebar(menuItems);

    const menuItemsPrepared = menuItems
        .filter((menu) => checkAccess(menu.roles))
        .map(({ key, submenus, route, value, url, blanc, title }: SideBarItems) => {
            if (submenus) {
                return {
                    key: value,
                    label: <div>{title}</div>,
                    className: "sidebar__menu-item sidebar__text",
                    children: [
                        ...submenus
                            .filter((menu) => checkAccess(menu.roles))
                            .map(({ value, title, route, blanc, url }: SideBarItems) => {
                                return {
                                    key: route === "in-developing" ? title : value,
                                    className: "sidebar__menu-item sidebar__submenu-text",
                                    label: (
                                        <>
                                            {title}{" "}
                                            {/* <Badge
                        offset={[0, 0]}
                        style={{ boxShadow: "none" }}
                        showZero={false}
                        size={"default"}
                        // count={badges[route]}
                      /> */}
                                        </>
                                    ),
                                    onClick: (info: any) =>
                                        onSubmenuClick(info, value, url, blanc, route),
                                };
                            }),
                    ],
                };
            } else {
                return {
                    key: route,
                    label: (
                        <>
                            {title}{" "}
                            {/* {badges[route] !== undefined && (
                <Badge
                  offset={[0, 0]}
                  style={{ boxShadow: "none" }}
                  showZero={false}
                  size={"default"}
                  count={badges[route]}
                /> */}
                            {/* )} */}
                        </>
                    ),
                    onClick: (info: any) => onMenuClick(info),
                };
            }
        });

    return (
        <Layout.Sider
            className="sidebar"
            breakpoint="lg"
            collapsedWidth={window.screen.width < 450 ? 0 : undefined}
            width={window.innerWidth > 1400 || window.innerWidth < 450 ? 300 : 200}
            collapsed={collapsed}
            collapsible={window.innerWidth > 450 ? true : false}
            trigger={null}
            onCollapse={() => {}}
            style={{
                overflow: "auto",
                height: "100vh",
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 999,
            }}
        >
            <img src={logo} alt="logo" className="sidebar__heading" />
            <Menu
                onOpenChange={(keys) => changeCurrentParrentOpenKeys(keys)}
                openKeys={currentParentMenuItem ? [...currentParentMenuItem] : undefined}
                theme="dark"
                mode="inline"
                className="sidebar__menu"
                items={menuItemsPrepared}
                selectedKeys={[currentMenuItem ?? ""]}
            />
        </Layout.Sider>
    );
};

export default Sidebar;
