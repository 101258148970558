export const statusColors = {
    error: "#ff3333",
    info: "white",
    processing: "red",
    success: "#33aa33",
};

export const ADD_MESSAGE_EMIT = "add-message";
export const UPDATE_MESSAGE_EMIT = "update-message";
export const UPDATE_LASTERS_MESSAGE_EMIT = "update-latest-message";
export const DELETE_MESSAGE_EMIT = "delete-message";
export const LOADING_STATE_EMIT = "loading-state";
