import { getNormalizedTime } from "@shared/lib/date";

const FlashAttemptTime = ({ time }: { time: string }) => {
    return (
        <span>
            {getNormalizedTime(time, {
                showDay: true,
                showMonth: true,
                showYear: true,
            })}
        </span>
    );
};

export default FlashAttemptTime;
