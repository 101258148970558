import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginPage } from "@pages/LoginPage";
import { useSelector } from "react-redux";
import { userModel } from "@entities/User";
import { useCheckAuth } from "@features/Auth/ChechAuth";
import { Spin } from "antd";
import AuthorizedRouter from "./AuthorizedRouter";

const AppRouter = () => {
    const isAuthorizated = useSelector(userModel.selectIsAuthorizated);
    const { loading } = useCheckAuth();
    if (loading) {
        return (
            <div>
                <Spin />
            </div>
        );
    }
    return (
        <BrowserRouter>
            {!isAuthorizated ? (
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<LoginPage />} />
                </Routes>
            ) : (
                <AuthorizedRouter />
            )}
        </BrowserRouter>
    );
};

export default AppRouter;
