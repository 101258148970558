import { devicesModel } from "@entities/Devices";
import { FirmwareDeviceSelectItem } from "@entities/FirmwareDevice";
import { names } from "@features/Devices/constants";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUploadAdditionalFilesModal } from "../../model/UseUploadAdditionalFilesModal";
import UploadAdditionalFilesForm from "./UploadAdditionalFilesForm";

interface IUploadAdditionalFilesButton {
    isOpen: boolean;
}

const UploadAdditionalFilesModal = ({ isOpen }: IUploadAdditionalFilesButton) => {
    const uploadingAdditionalFiles = useSelector(devicesModel.selectEditingAdditionalFiles);
    const [isDisableUploadButton, setIsDisableUploadButton] = useState<boolean>(false);
    const { onClose, onOk } = useUploadAdditionalFilesModal(setIsDisableUploadButton);
    useEffect(() => {
        if (uploadingAdditionalFiles) {
            if (
                uploadingAdditionalFiles.additional_files.bootloader &&
                uploadingAdditionalFiles.additional_files.ota_data_initial &&
                uploadingAdditionalFiles.additional_files.partitions
            ) {
                setIsDisableUploadButton(true);
            } else setIsDisableUploadButton(false);
        }
    }, [uploadingAdditionalFiles?.additional_files]);
    if (uploadingAdditionalFiles)
        return (
            <Modal destroyOnClose open={!!uploadingAdditionalFiles} onCancel={onClose} onOk={onOk}>
                <FirmwareDeviceSelectItem
                    label={"Тип устройства"}
                    value={names[uploadingAdditionalFiles.device_type]}
                    disabled={true}
                />
                <FirmwareDeviceSelectItem
                    label={"Подтип устройства"}
                    value={uploadingAdditionalFiles.subtype_name}
                    disabled={true}
                />

                <UploadAdditionalFilesForm
                    isDisableUploadButton={isDisableUploadButton}
                    additionalFiles={uploadingAdditionalFiles.additional_files}
                />
            </Modal>
        );
    else return <></>;
};

export default UploadAdditionalFilesModal;
