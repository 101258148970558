import { names } from "./constants";

export const prepareData = (subtypes: TGroupedSubType[]): TTableDataGroupedSubType[] => {
    return subtypes.map(
        (
            { device_type, device_type_id, version, files, series, id, subtype, additionalFiles },
            index
        ) => {
            return {
                device_type: device_type,
                device_type_id,
                name: names[device_type],
                version,
                files,
                id,
                subtype,
                series,
                additionalFiles: additionalFiles,
                key: index,
            };
        }
    );
};

export const getGroupedVersions = (versions: TVersions): TGroupedVersion[] => {
    const versionsGrouped: TGroupedVersion[] = [];
    let tmpGroups: any[] = [];
    if (!!versions)
        versions.forEach((item: TVersion) => {
            if (tmpGroups.includes(names[item.device_type])) {
                versionsGrouped.map((version) => {
                    if (names[version.device_type] === names[item.device_type]) {
                        return { ...version, subtypes: version.subtypes.push(item) };
                    }
                    return version;
                });
            } else {
                versionsGrouped.push({
                    name: names[item.device_type],
                    device_type_id: item.device_type_id,
                    device_type: item.device_type,
                    subtypes: [item],
                });
                tmpGroups = [...tmpGroups, names[item.device_type]];
            }
        });
    return versionsGrouped;
};

export const getGroupedWithAdditionalFiles = (
    grouped: TGroupedVersion[],
    additionalFiles: TAdditionalFiles
) => {
    const groupedWithAdditionalFiles: TGroupedVersion[] = [];
    grouped.forEach((group) => {
        const subtypes: TGroupedSubType[] = [];
        group.subtypes.forEach((subtype) => {
            const additionalFilesField = additionalFiles[group.device_type]?.[subtype.subtype]
                ? additionalFiles[group.device_type][subtype.subtype]
                : [];
            subtypes.push({ ...subtype, additionalFiles: additionalFilesField });
        });

        groupedWithAdditionalFiles.push({ ...group, subtypes: subtypes });
    });
    return groupedWithAdditionalFiles;
};
