import { emitter } from "@shared/emitter";
import { useEffect, useState } from "react";
import {
    ADD_MESSAGE_EMIT,
    DELETE_MESSAGE_EMIT,
    LOADING_STATE_EMIT,
    UPDATE_LASTERS_MESSAGE_EMIT,
    UPDATE_MESSAGE_EMIT,
} from "../constants";

export const useTerminal = (terminalName: string) => {
    const [messages, setMessages] = useState<TTerminalMessage[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const addMessage = (message: TNewTerminalMessage) => {
        const newMessage = {
            ...message,
            id: message.time,
        };
        setMessages((messages) => [...messages, newMessage]);
    };
    const updateMessage = (message: TTerminalMessage) => {
        setMessages((messages) =>
            messages.map((message_) =>
                message.id === message_.id ? { ...message_, ...message } : message_
            )
        );
    };

    const deleteMessage = (messageId: number) => {
        setMessages((messages) => messages.filter((message_) => messageId !== message_.id));
    };
    const updateLatestMessage = (messageText: string) => {
        setMessages((messages) =>
            messages.map((message_, index) =>
                index === messages.length - 1 ? { ...message_, message: messageText } : message_
            )
        );
    };
    const setLoadingState = (state: boolean) => {
        setIsLoading(state);
    };

    const clearTerminal = () => {
        setMessages([]);
    };

    useEffect(() => {
        emitter.on(terminalName + ADD_MESSAGE_EMIT, addMessage);
        emitter.on(terminalName + UPDATE_MESSAGE_EMIT, updateMessage);
        emitter.on(terminalName + LOADING_STATE_EMIT, setLoadingState);
        emitter.on(terminalName + DELETE_MESSAGE_EMIT, deleteMessage);
        emitter.on(terminalName + UPDATE_LASTERS_MESSAGE_EMIT, updateLatestMessage);
        return () => {
            emitter.removeAllListeners(terminalName + ADD_MESSAGE_EMIT);
            emitter.removeAllListeners(terminalName + UPDATE_LASTERS_MESSAGE_EMIT);
            emitter.removeAllListeners(terminalName + LOADING_STATE_EMIT);
            emitter.removeAllListeners(terminalName + UPDATE_MESSAGE_EMIT);
            emitter.removeAllListeners(terminalName + DELETE_MESSAGE_EMIT);
        };
    }, []);
    return {
        clearTerminal,
        isLoading,
        messages,
    };
};
