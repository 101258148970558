import axios from "axios";

export const clientId = "95fccb78-346a-433d-ad3d-597a38554111";
export const clientSecret = "nrObsW7Ou7ibIQYeILwoT5NbcR0e9KJw6yEgdtRK";
export const grantType = "password";

export const apiUrl = process.env.REACT_APP_API_URL;
export const apiUrlAuth = "https://auth.vakio.ru";

export const $api = axios.create({
    baseURL: apiUrl,
});

export const $apiAuth = axios.create({
    baseURL: apiUrlAuth,
});

$api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");

    config.headers.Authorization = `Bearer ${token}`;
    return config;
});
$apiAuth.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");

    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

$api.interceptors.response.use(
    (config) => {
        if (config.data.code < 200 || config.data.code > 299) throw "Error";
        return config;
    },
    async (error) => {
        if (error?.response?.status === 401) {
        }
        throw error;
    }
);
