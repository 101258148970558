import { Spin } from "antd";

interface INotFoundContent {
    condition: boolean;
}

const NotFoundContent = ({ condition }: INotFoundContent) => {
    return (
        <div>
            {condition ? (
                <div className="empty-content">
                    <Spin size="small" />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default NotFoundContent;
