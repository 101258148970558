import { devicesModel } from "@entities/Devices";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Upload, UploadProps } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { useState } from "react";

export const useUploadVersion = () => {
    const { loadVersions } = devicesModel.useLoadDevices(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const props: UploadProps = {
        name: "file",
        action: "https://old.devices.vakio.ru/versions/upload",
        headers: {
            authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        beforeUpload: (file) => {
            setIsLoading(true);
            const isBin = file.type === "application/octet-stream";
            if (!isBin) {
                notificationEmit({
                    title: "Ошибка загрузки файла",
                    description: `${file.name} is not a bin file`,
                    type: "error",
                });
            }
            return isBin || Upload.LIST_IGNORE;
        },
    };

    const onChange = (file: UploadChangeParam<UploadFile<any>>) => {
        if (file?.file?.percent === 0) {
            setIsLoading(true);
        }
        if (file.file) {
            if (file.file.status === "error") {
                notificationEmit({
                    title: "Ошибка загрузки файла",
                    description: file.file.response.message,
                    type: "error",
                });
            }
            if (file.file.status === "done") {
                notificationEmit({
                    title: "Файл успешно загружен",
                    description: `Файл ${file.file.name} был успешно загружен`,
                    type: "success",
                });
                loadVersions();
            }
            setIsLoading(false);
        }
    };

    return {
        props,
        isLoading,
        onChange,
    };
};
