import { devicesApi, devicesModel } from "@entities/Devices";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const useDeviceSubTypeVersions = () => {
    const { loadVersions } = devicesModel.useLoadDevices();
    const deleteVersion = (name: string) => {
        devicesApi
            .deleteVersionFile(name)
            .then(() => {
                loadVersions();
                notificationEmit({
                    title: "Удаление версии прошивки",
                    description: `Версия прошивки успешно удалена`,
                    type: "info",
                });
            })
            .catch((e) => {
                notificationEmit({
                    title: "Не удалось удалить файл",
                    type: "error",
                });
            });
    };
    return { deleteVersion };
};
