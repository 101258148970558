import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { flashAttemptsApi } from "@entities/FlashAttempts";
import { useEsptoolErase, useEsptoolFlash } from "@shared/esptool";
import { PORT_NOT_SELECTED_ERROR, useSerial } from "@shared/serial";
import { useTerminalEmits } from "@shared/ui/Terminal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fillFileArray } from "../lib";
import { ESPLoader, Transport } from "@shared/esptool/esptool-js/lib";

export const useFirmwareCommand = (
    transport: React.MutableRefObject<Transport | undefined>,
    esptool: React.MutableRefObject<ESPLoader | undefined>
) => {
    const terminal = useTerminalEmits("firmware");
    const { requestSerialPort, closeSerial } = useSerial(transport, esptool);
    const { startEspErase } = useEsptoolErase();
    const { startEspFlash } = useEsptoolFlash();
    const currentVersion = useSelector(firmwareDeviceModel.selectCurrentVersion);
    const currentDeviceType = useSelector(firmwareDeviceModel.selectCurrentDeviceType);
    const currentDeviceSubType = useSelector(firmwareDeviceModel.selectCurrentDeviceSubType);
    const macAddress = useSelector(firmwareDeviceModel.selectCurrentMac);
    const [isActive, setIsActive] = useState(false);
    const [firmwaringIsDone, setFirmwaringIsDone] = useState<boolean>(false);
    const [statusOfFirmwaring, setStatusOfFirmwaring] = useState<"success" | "failed">("failed");

    useEffect(() => {
        if (firmwaringIsDone === true && macAddress) {
            flashAttemptsApi
                .sendInformationAboutFlash({
                    mac: macAddress!,
                    type: currentDeviceType!,
                    subtype: currentDeviceSubType!,
                    version: currentVersion!.version,
                    status: statusOfFirmwaring!,
                })
                .catch((e: any) => {
                    console.log(e.message);
                });
            setFirmwaringIsDone(false);
        }
    }, [firmwaringIsDone]);

    const getSerial = async () => {
        try {
            terminal.addInfoMessageEmit("Ожидание COM-порта");
            const transport = await requestSerialPort();
            return transport;
        } catch (error: any) {
            if (error.code === PORT_NOT_SELECTED_ERROR)
                terminal.addErrorMessageEmit("Порт не выбран.");
            return null;
        }
    };
    const eraseCommand = async () => {
        setIsActive(true);
        const transport = await getSerial();
        if (transport) {
            try {
                terminal.addInfoMessageEmit("Начинаем очистку памяти");
                await startEspErase(transport);
                terminal.addSuccessMessageEmit("Очистка памяти устройства завершена");
            } catch (error: any) {
                terminal.addErrorMessageEmit(
                    "Не удалось выполнить операцию\n" + (error?.message ?? "")
                );
                closeSerial();
                throw new Error();
            } finally {
                setIsActive(false);
            }
        }
    };

    const flashCommand = async () => {
        const transport = await getSerial();
        if (transport)
            try {
                terminal.addInfoMessageEmit("Начинаем прошивку устройства");

                const fileArray = await fillFileArray(currentVersion!);
                await startEspFlash(transport, fileArray);
                terminal.addSuccessMessageEmit("Прошивка устройства завершена");
            } catch (error: any) {
                terminal.addErrorMessageEmit(
                    "Не удалось выполнить операцию\n" + (error?.message ?? "")
                );
                closeSerial();
                throw new Error();
            } finally {
                setIsActive(false);
            }
    };

    const firmwareCommand = async () => {
        try {
            await eraseCommand();
            await flashCommand();
            setStatusOfFirmwaring("success");
        } catch (e) {
            setStatusOfFirmwaring("failed");
        } finally {
            setFirmwaringIsDone(true);
            setIsActive(false);
        }
    };
    return {
        isActive,
        eraseCommand,
        firmwareCommand,
    };
};
