import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { loadFlashAttemptsHistoryThunk } from "./FlashAttemptsThunk";

export interface IFlashAttempts {
    initialData: TFlashAttemptsData;
    editingFlashAttempt: TFlashAttemptData | null;
    isLoading: boolean;
}

const initialState: IFlashAttempts = {
    initialData: [],
    editingFlashAttempt: null,
    isLoading: true,
};

export const flashAttemptsSlice = createSlice({
    name: "flashAttempts",
    initialState,
    reducers: {
        setInitialData: (state, action: PayloadAction<TFlashAttemptsData>) => {
            state.initialData = action.payload;
        },
        setEditingFlashAttempt: (state, action: PayloadAction<TFlashAttemptData | null>) => {
            state.editingFlashAttempt = action.payload;
        },
    },
    extraReducers: (builder) => {
        loadFlashAttemptsHistoryBuilder(builder);
    },
});

const loadFlashAttemptsHistoryBuilder = (builder: ActionReducerMapBuilder<IFlashAttempts>) => {
    builder.addCase(loadFlashAttemptsHistoryThunk.fulfilled, (state, action) => {
        state.initialData = action.payload;
        state.isLoading = false;
    });
    builder.addCase(loadFlashAttemptsHistoryThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(loadFlashAttemptsHistoryThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
    });
};

export const { setInitialData, setEditingFlashAttempt } = flashAttemptsSlice.actions;

export default flashAttemptsSlice.reducer;
