import { devicesModel } from "@entities/Devices";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useDeviceVersionModal = () => {
    const dispatch = useDispatch<AppDispatch>();
    const editingSubType = useSelector(devicesModel.selectEditingSubType);
    const [error, setError] = useState<TValidationError>({});

    const onChange = (name: string, value: any) => {
        if (editingSubType) {
            dispatch(
                devicesModel.setEditingSubType({
                    ...editingSubType,
                    [name]: value,
                })
            );
        }
    };

    const onClose = () => {
        dispatch(devicesModel.setEditingSubType(null));
        setError({});
    };
    const onOk = () => {
        if (editingSubType) {
            dispatch(devicesModel.updateVersionThunk(editingSubType)).finally(() => {
                onClose();
            });
        }
    };

    return {
        error,
        editingSubType,
        onClose,
        onOk,
        onChange,
    };
};
