import { FlashAttemptsTable } from "@features/FlashAttempts";
import { useHeaderLayout } from "@shared/ui/Header";
import { FlashAttemptsTableFilters } from "@widgets/Filters/FlashAttemptsTableFilters";

const FirmwareAttemptsPage = () => {
    useHeaderLayout({ title: "История прошивок устройств" });
    return (
        <div>
            <FlashAttemptsTableFilters />
            <FlashAttemptsTable />
        </div>
    );
};

export default FirmwareAttemptsPage;
