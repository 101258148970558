import { useEffect, useState } from "react";
import { emitter } from "../../emitter";

type THeaderLayout = {
    subtitle?: string;
    title?: string;
};
export const useHeader = () => {
    const [layout, setLayout] = useState<THeaderLayout>({});
    const handleEvent = (value: THeaderLayout) => {
        setLayout(value);
    };
    useEffect(() => {
        emitter.on("header-emit", handleEvent);
        return () => {
            emitter.removeAllListeners("header-emit");
        };
    }, []);

    return {
        layout,
    };
};

export const emitHeaderLayout = (value: THeaderLayout) => {
    emitter.emit("header-emit", value);
};
export const useHeaderLayout = (value: THeaderLayout) => {
    useEffect(() => {
        emitter.emit("header-emit", value);
        return () => {
            emitter.emit("header-emit", {});
        };
    }, [value.title, value.subtitle]);
};
