import { firmwareDeviceApi } from "@entities/FirmwareDevice";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

type TUrlWithAdresses = {
    url: string;
    flashAddress: string;
}[];

type TFile = {
    data: string;
    address: number;
};

export const fillFileArray = async (version: TCurrentVersion) => {
    const urlWithAdresses: TUrlWithAdresses = await compareUrlsWithAddresses(version);
    const fileArray: TFile[] = [];
    for (const item of urlWithAdresses) {
        const { url, flashAddress } = item;
        const address = parseInt(flashAddress.split("0x")[1], 16);
        const response = await firmwareDeviceApi.getDeviceFirmwareFile(url).catch((e) => {
            throw new Error();
        });
        const data = response.data;
        const fileBinary = await new Promise((resolve, reject) => {
            const blob = new Blob([data], { type: "application/octet-stream" });
            const reader = new FileReader();

            reader.onload = (ev: ProgressEvent<FileReader>) => {
                resolve(ev.target!.result);
                console.log("read binary done!", ev.target!.result);
            };
            reader.onerror = () => {
                reject("Не удалось прочитать файл");
            };
            console.log("start read as binary string");

            reader.readAsBinaryString(blob);
        });

        // const base64 = bufferToBase64(data);
        fileArray.push({
            data: fileBinary as unknown as string,
            address,
        });
    }

    // urlWithAdresses.forEach(async ({ url, flashAddress }) => {
    //     const address = parseInt(flashAddress.split("0x")[1], 16);
    //     const response = await firmwareDeviceApi.getDeviceFirmwareFile(url).catch((e) => {
    //         throw new Error();
    //     });
    //     const data = response.data;
    //     // const base64 = bufferToBase64(data);
    //     fileArray.push({
    //         data: data,
    //         address,
    //     });
    // });
    return fileArray.sort((a, b) => {
        return a.address - b.address;
    });
};

const compareUrlsWithAddresses = async (version: TCurrentVersion) => {
    const urlWithAdresses: TUrlWithAdresses = [];
    await firmwareDeviceApi
        .getDeviceFirmwareURLs(version!.id)
        .then(({ data: { data } }) => {
            urlWithAdresses.push({
                url: data.firmware,
                flashAddress: data.addresses.firmware,
            });
            if (data.additional) {
                const additionalFilesUrlValues = Object.entries(data.additional);
                additionalFilesUrlValues.forEach(([key, value], index) => {
                    urlWithAdresses.push({
                        url: value,
                        flashAddress: data.addresses.additional[key],
                    });
                });
            }
        })
        .catch((e: any) => {
            notificationEmit({
                title: "Ошибка получения версий",
                type: "error",
            });
            throw new Error();
        });
    return urlWithAdresses;
};

const bufferToBase64 = (buf: any) => {
    const chunks = [];
    const uint8 = new Uint8Array(buf);
    const chunkSize = 0x8000;
    for (let i = 0; i < uint8.length; i += chunkSize) {
        const chunk = uint8.subarray(i, Math.min(i + chunkSize, uint8.length));
        chunks.push(String.fromCharCode(...chunk));
    }
    return btoa(chunks.join(""));
};
