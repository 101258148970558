import React from "react";
import "./FormItem.scss";

interface IFormItemProps {
    label?: string;
    required?: boolean;
    error?: boolean;
    style?: React.CSSProperties | undefined;
    children: React.ReactElement;
}

const FormItem = ({ label, required, error, style, children }: IFormItemProps) => {
    return (
        <div className="form-item" style={style}>
            {label && (
                <div className="form-item__label">
                    {required && <span style={{ color: "red", fontWeight: 700 }}>*</span>} {label}
                </div>
            )}
            <div className={`form-item__body`}>{children}</div>
            {error && <div className="form-item__error">Это поле обязательно к заполнению</div>}
        </div>
    );
};

export default FormItem;
