import moment from "moment";
import dayjs from "dayjs";

export const getUnixTimestamp = () => {
    return moment().valueOf();
};

export const getDatePickerFormat = (date: string | undefined): dayjs.Dayjs | null => {
    return date ? dayjs(date, "DD-MM-YYYY") : null;
};
export const getNormalizedTime = (
    time: string,
    config: {
        showDay: boolean;
        showMonth: boolean;
        showYear: boolean;
    } = {
        showDay: false,
        showMonth: false,
        showYear: false,
    }
): any => {
    if (!time) {
        return "---";
    }
    const date = new Date(time);
    const locale = navigator.language;
    const dateOptions: Intl.DateTimeFormatOptions = {
        month: config.showDay ? "numeric" : undefined, // numeric "2-digit", "narrow", "short" и "long"
        day: config.showMonth ? "numeric" : undefined, // 2-digit
        year: config.showYear ? "numeric" : undefined, // 2-digit
        // era: "long",
        // weekday: "short", // "narrow", "short" и "long"
        // timeZoneName: "short",
        hour: "numeric",
        // hour12: true,
        minute: "numeric",
        second: "numeric",
    };
    const UserDate = new Intl.DateTimeFormat("RU-ru", dateOptions);
    return UserDate.format(date);
};
