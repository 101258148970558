import { flashAttemptsModel } from "@entities/FlashAttempts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadFlashAttemptsHistoryThunk } from "./FlashAttemptsThunk";
export const useLoadFlashAttempts = (preload?: boolean) => {
    const flashAttempts = useSelector(flashAttemptsModel.selectFlashAttemptsData);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadFlashAttempts();
        }
    }, []);

    const loadFlashAttempts = () => {
        dispatch(loadFlashAttemptsHistoryThunk({}));
    };

    return {
        flashAttempts,
        loadFlashAttempts,
    };
};
