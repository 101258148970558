import { DeviceVersionItemTable } from "@entities/Devices";
import { TableColumnsType } from "antd";
import { prepareData } from "../../lib";
import DeviceSubTypeVersions from "../DeviceSubTypeVersions/DeviceSubTypeVersions";
import DeviceTableActions from "../DeviceTableActions";
import DeviceSubTypeAdditionalFiles from "../DeviceSubTypeAdditionalFiles";

interface IDeviceVersionItem {
    item: TGroupedVersion;
    setIsOpen: (item: boolean) => void;
}

const DeviceVersionItem = ({ item, setIsOpen }: IDeviceVersionItem) => {
    const columns: TableColumnsType<TTableDataGroupedSubType> = [
        { title: "Название", dataIndex: "name", key: "name", width: "10%" },
        { title: "Код устройства", dataIndex: "device_type", key: "device_type", width: "10%" },
        { title: "Подтип", dataIndex: "subtype", key: "subtype", width: "10%" },
        { title: "Версия", dataIndex: "version", key: "version", width: "10%" },
        {
            title: "Файлы",
            dataIndex: "",
            key: "",
            width: "fit-content",
            render: (value: TTableDataGroupedSubType) => <DeviceSubTypeVersions value={value} />,
        },
        {
            title: "Вспомогательные файлы",
            dataIndex: "additionalFiles",
            key: "additionalFiles",
            width: "fit-content",
            render: (value: string[], render) => {
                return (
                    <DeviceSubTypeAdditionalFiles
                        additionalFilesNames={value}
                        deviceTypeId={render.device_type}
                        subTypeName={render.subtype}
                    />
                );
            },
        },
        {
            title: "Действия",
            dataIndex: "",
            key: "actions",
            render: (value: TTableDataGroupedSubType) => (
                <DeviceTableActions setIsOpen={setIsOpen} value={value} />
            ),
        },
    ];
    const preparedData = prepareData(item.subtypes);

    return <DeviceVersionItemTable columns={columns} data={preparedData} />;
};

export default DeviceVersionItem;
