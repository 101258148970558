import { CSSProperties, ReactNode } from "react";
import "./DefaultCard.scss";
interface IDefaultCard {
    className?: string;
    style?: CSSProperties;
    children: ReactNode;
}

const DefaultCard = ({ className, style, children }: IDefaultCard) => {
    return (
        <div className={`default-card ${className ?? ""}`} style={style}>
            {children}
        </div>
    );
};

export default DefaultCard;
