import { devicesModel } from "@entities/Devices";
import { DeviceVersionItem } from "@features/Devices";
import { getGroupedVersions, getGroupedWithAdditionalFiles } from "@features/Devices/lib";
import { ErrorRequest } from "@shared/ui/Errors";
import { Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DevicesMenuWidget from "./DevicesMenuWidget/DevicesMenuWidget";
import DevicesModals from "./DevicesModals/DevicesModals";

const DeviceMainWidget = () => {
    const [isOpenAdditionalModal, setIsOpenAdditionalModal] = useState<boolean>(false);
    const isLoading = useSelector(devicesModel.selectDevicesSliceIsLoading);
    const error = useSelector(devicesModel.selectError);
    const lastActiveTab = localStorage.getItem("lastActiveTab") ?? "0";
    const { versions } = devicesModel.useLoadDevices(true);
    const { additionalFiles } = devicesModel.useLoadAdditionalFiles(true);
    const groupedVersions = useSelector(devicesModel.selectGroupedVersions);
    const dispatch = useDispatch();

    useEffect(() => {
        if (versions.length > 0) {
            const grouped = getGroupedVersions(versions);
            if (additionalFiles && grouped.length > 0) {
                const groupedWithAdditionalFiles = getGroupedWithAdditionalFiles(
                    grouped,
                    additionalFiles
                );
                dispatch(devicesModel.setGroupedVersions(groupedWithAdditionalFiles));
            } else {
                dispatch(devicesModel.setGroupedVersions(grouped));
            }
        }
    }, [additionalFiles, versions]);

    if (error) {
        return <ErrorRequest error={error} />;
    }
    if (isLoading) {
        return <Spin spinning={true} fullscreen />;
    }
    if (!isLoading && !error)
        return (
            <div>
                <DevicesMenuWidget setIsOpen={setIsOpenAdditionalModal} />
                <DevicesModals isOpen={isOpenAdditionalModal} />
                <Tabs
                    onTabClick={(key: string) => localStorage.setItem("lastActiveTab", key)}
                    defaultActiveKey={lastActiveTab}
                    style={{
                        height: "fit-content",
                        minHeight: "calc(100vh - 170px)",
                    }}
                    items={groupedVersions.map((item, index) => {
                        return {
                            label: item.name,
                            key: index.toString(),
                            children: (
                                <DeviceVersionItem
                                    item={item}
                                    setIsOpen={setIsOpenAdditionalModal}
                                />
                            ),
                        };
                    })}
                />
            </div>
        );
    else return <></>;
};

export default DeviceMainWidget;
