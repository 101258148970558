import { Empty } from "antd";

const ErrorRequest = ({ error }: { error: any }) => {
    return (
        <Empty
            description={
                <div>
                    <span>Ошибка: {error.error.message}</span>
                    <br />
                    <span>Описание: {error.type}</span>
                </div>
            }
        />
    );
};

export default ErrorRequest;
