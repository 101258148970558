import { useHeaderLayout } from "@shared/ui/Header";
import { Terminal } from "@shared/ui/Terminal";
import { FirmwareActionBar } from "@widgets/FirmwareWidgets/FirmwareActionBar";

const FirmwarePage = () => {
    useHeaderLayout({ title: "Прошивка устройств" });
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <FirmwareActionBar />
            <Terminal terminalName="firmware" />
        </div>
    );
};

export default FirmwarePage;
