import { devicesModel } from "@entities/Devices";
import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { flashAttemptsModel } from "@entities/FlashAttempts";
import { userModel } from "@entities/User";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    reducer: {
        user: userModel.userSlice.reducer,
        firmwareDevice: firmwareDeviceModel.firmwareDeviceSlice.reducer,
        flashAttempts: flashAttemptsModel.flashAttemptsSlice.reducer,
        devicesSlice: devicesModel.devicesSlice.reducer,
    },
});
