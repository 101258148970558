import { DefaultOptionType } from "antd/es/select";
import { FirmwareDeviceSelectItem } from "@entities/FirmwareDevice";
import { useFirmwareDeviceSubType } from "../model";

interface IFirmwareDeviceSubType {
    deviceSubTypeOptions: DefaultOptionType[];
}

const FirmwareDeviceSubType = ({ deviceSubTypeOptions }: IFirmwareDeviceSubType) => {
    const { deviceSubType, onSelectDeviceSubType } = useFirmwareDeviceSubType();
    const lastChoosenDeviceSubType = localStorage.getItem("deviceSubType");

    return (
        <FirmwareDeviceSelectItem
            label={"Подтип устройства"}
            placeholder={"Выберите подтип устройства"}
            value={lastChoosenDeviceSubType ?? deviceSubType ?? undefined}
            options={deviceSubTypeOptions}
            onSelect={onSelectDeviceSubType}
            disabled={deviceSubTypeOptions.length < 1}
        />
    );
};

export default FirmwareDeviceSubType;
