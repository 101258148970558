import "./UploadAdditionalFiles.scss";
import UploadAdditionalFilesItemUni from "./UploadAdditionalFilesItem.tsx/UploadAdditionalFilesItemUni";

interface IUploadAdditionalFilesForm {
    additionalFiles: TUploadingAdditionalFiles;
    isDisableUploadButton: boolean;
}

const UploadAdditionalFilesForm = ({ isDisableUploadButton }: IUploadAdditionalFilesForm) => {
    return (
        <>
            <UploadAdditionalFilesItemUni isDisableUploadButton={isDisableUploadButton} />
            {/* На будущее для выбора адресов вспомогательных файлов */}
            {/* <UploadBootloader
                additionalFileAddress="bootloader"
                setAdditionalFiles={setAdditionalFiles}
                additionalFiles={additionalFiles}
            />
            <UploadOtaDataInitial
                additionalFileAddress="ota_data_initial"
                setAdditionalFiles={setAdditionalFiles}
                additionalFiles={additionalFiles}
            />
            <UploadPartitions
                additionalFileAddress="partitions"
                setAdditionalFiles={setAdditionalFiles}
                additionalFiles={additionalFiles}
            /> */}
        </>
    );
};

export default UploadAdditionalFilesForm;
