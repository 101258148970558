import { $api } from "@shared/api/api";
import { useEffect, useState } from "react";

export const useSiteLayout = () => {
    const [loading, setLoading] = useState(false);
    const [routes, setRoutes] = useState(JSON.parse(localStorage.getItem("navigation") || "[]"));
    useEffect(() => {
        setLoading(true);
        $api.get("/api/routes", { baseURL: "https://portal.vakio.ru" })
            .then((response: any) => {
                setRoutes(response.data);
                localStorage.setItem("navigation", JSON.stringify(response.data));
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        routes,
        loading,
    };
};
