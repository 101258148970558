import type { TableProps } from "antd";
import { Table } from "antd";

interface IFlashAttempts {
    data: TTableFlashAttemptDataType[];
    columns: TableProps<TTableFlashAttemptDataType>["columns"];
    isLoading: boolean;
    selectedIds: React.Key[];
    onSelectChange: (item: React.Key[]) => void;
    onChange?: TableProps<TTableFlashAttemptDataType>["onChange"];
}

const FlashAttempts = ({
    data,
    columns,
    isLoading,
    selectedIds,
    onSelectChange,
    onChange,
}: IFlashAttempts) => {
    const rowSelection = {
        selectedIds,
        onChange: onSelectChange,
    };

    return (
        <Table
            rowSelection={rowSelection}
            loading={isLoading}
            columns={columns}
            dataSource={data}
            onChange={onChange}
        />
    );
};

export default FlashAttempts;
