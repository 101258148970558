import { FirmwareDeviceSelectItem, firmwareDeviceModel } from "@entities/FirmwareDevice";
import { useSelector } from "react-redux";

const FirmwareDeviceVersion = () => {
    const currentVersion = useSelector(firmwareDeviceModel.selectCurrentVersion);
    const storage = localStorage.getItem("deviceVersion");
    const lastChoosenVersion = storage ? JSON.parse(storage).version : null;
    return (
        <FirmwareDeviceSelectItem
            label={"Версия прошивки"}
            placeholder={"Версия будет выбрана автоматически"}
            value={currentVersion?.version ?? lastChoosenVersion ?? undefined}
            options={[]}
            onSelect={() => {}}
            disabled={true}
        />
    );
};

export default FirmwareDeviceVersion;
