import { devicesModel } from "@entities/Devices";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Upload, UploadProps } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useUploadAdditionalFile = () => {
    const dispatch = useDispatch();
    const uploadingAdditionalFiles = useSelector(devicesModel.selectEditingAdditionalFiles);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [binFilesBuff, setBinFilesBuff] = useState<TUploadingAdditionalFiles | null>(null);

    const loadedAddressesRef = useRef<string[] | null>([]);

    useEffect(() => {
        if (uploadingAdditionalFiles && binFilesBuff) {
            dispatch(
                devicesModel.setEditingAdditionalFiles({
                    ...uploadingAdditionalFiles,
                    additional_files: binFilesBuff,
                })
            );
        }
    }, [binFilesBuff]);

    useEffect(() => {
        if (uploadingAdditionalFiles === null) {
            loadedAddressesRef.current = [];
            setBinFilesBuff(null);
        }
    }, [uploadingAdditionalFiles]);

    const getAddress = (filename?: string) => {
        return filename?.includes("bootloader")
            ? "bootloader"
            : filename?.includes("ota_data_initial")
              ? "ota_data_initial"
              : filename?.includes("partitions")
                ? "partitions"
                : null;
    };

    const props: UploadProps = {
        multiple: true,
        name: "file",
        onRemove: (file) => {
            const address = getAddress(file.name);
            if (address) {
                const additionalFilesWithoutDeletedFile = {
                    ...uploadingAdditionalFiles!.additional_files,
                };
                delete additionalFilesWithoutDeletedFile[address];
                const index = loadedAddressesRef.current!.indexOf(address);
                loadedAddressesRef.current!.splice(index, 1);
                setBinFilesBuff({ ...additionalFilesWithoutDeletedFile });
            } else {
                return false;
            }
        },
        beforeUpload: (file) => {
            const address = getAddress(file.name);
            const isBin = file.type === "application/octet-stream";
            if (isBin) {
                if (address) {
                    if (!loadedAddressesRef.current!.includes(address)) {
                        notificationEmit({
                            title: "Файл успешно загружен",
                            description: `Файл ${file.name} был успешно загружен`,
                            type: "success",
                        });
                        loadedAddressesRef.current!.push(address);
                        return false;
                    } else {
                        notificationEmit({
                            type: "info",
                            title: `Вы уже загрузили файл для ${address}`,
                        });
                        return Upload.LIST_IGNORE;
                    }
                }
            } else {
                notificationEmit({
                    title: "Ошибка загрузки файла",
                    description: `Файл ${file.name} не является .bin файлом`,
                    type: "error",
                });
                return Upload.LIST_IGNORE;
            }
        },
    };

    const onChange = async (file: UploadChangeParam<UploadFile<any>>) => {
        const files: { [address: string]: RcFile } = {};
        file.fileList.forEach((file) => {
            const address = getAddress(file.name);
            if (address) {
                files[address] = file.originFileObj!;
            }
        });
        setBinFilesBuff({
            type_id: uploadingAdditionalFiles!.additional_files.type_id,
            subtype_name: uploadingAdditionalFiles!.additional_files.subtype_name,
            ...files,
        });
    };

    return { props, isLoading, onChange };
};
