import { DeleteOutlined } from "@ant-design/icons";
import { devicesModel } from "@entities/Devices";
import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";

const DeviceSubTypeAdditionalFiles = ({
    additionalFilesNames,
    deviceTypeId,
    subTypeName,
}: {
    additionalFilesNames?: string[];
    deviceTypeId: string;
    subTypeName: string;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const deleteClick = () => {
        const formData = new FormData();
        formData.append("type_id", deviceTypeId);
        formData.append("subtype_name", subTypeName);
        dispatch(devicesModel.deleteAdditionalFilesThunk(formData));
    };

    if (additionalFilesNames && additionalFilesNames.length > 0)
        return (
            <div style={{ display: "flex" }}>
                <ul className="files-list" style={{ overflow: "hidden" }}>
                    {additionalFilesNames.map((fileName: string, index: number) => {
                        return (
                            <li key={index} className="files-list__item">
                                <span>
                                    {index + 1}. {fileName}
                                </span>
                            </li>
                        );
                    })}
                </ul>
                <Popconfirm
                    title={
                        <>
                            <span style={{ textAlign: "center" }}>Все вспомогательные файлы</span>
                            <br />
                            <span style={{ textAlign: "center" }}>будут удалены!</span>
                        </>
                    }
                    okText="Удалить"
                    onConfirm={deleteClick}
                    cancelText="Отмена"
                >
                    <DeleteOutlined
                        style={{
                            color: "red",
                        }}
                    />
                </Popconfirm>
            </div>
        );
    else
        return (
            <div style={{ color: "gray", textAlign: "center", width: 150 }}>
                Дополнительные файлы не найдены
            </div>
        );
};

export default DeviceSubTypeAdditionalFiles;
