import {
    FirmwareButton,
    FirmwareDeviceSubType,
    FirmwareDeviceType,
    FirmwareDeviceVersion,
} from "@features/FirmwareFeatures/FirmwareDevice";
import FirmwareChangePortButton from "@features/FirmwareFeatures/FirmwareDevice/ui/FirmwareChangePortButton";
import FirmwareStopButton from "@features/FirmwareFeatures/FirmwareDevice/ui/FirmwareStopButton";
import { ESPLoader, Transport } from "@shared/esptool/esptool-js/lib";
import { DefaultCard } from "@shared/ui/Cards";
import { DefaultOptionType } from "antd/es/select";
import { useRef, useState } from "react";
interface IFirmwareActionBar {}

const FirmwareActionBar = ({}: IFirmwareActionBar) => {
    const [subTypeOptions, setSubTypeOptions] = useState<DefaultOptionType[]>([]);
    const transport = useRef<Transport | undefined>();
    const esptool = useRef<ESPLoader>();
    return (
        <DefaultCard
            style={{
                height: "fit-content",
                margin: "0px 10px",
                minWidth: 300,
            }}
        >
            <FirmwareDeviceType setDeviceSubTypeOptions={setSubTypeOptions} />
            <FirmwareDeviceSubType deviceSubTypeOptions={subTypeOptions} />
            <FirmwareDeviceVersion />
            <FirmwareButton esptool={esptool} transport={transport} />
            <FirmwareStopButton esptool={esptool} transport={transport} />
            <FirmwareChangePortButton esptool={esptool} transport={transport} />
        </DefaultCard>
    );
};

export default FirmwareActionBar;
