import logo from "./logo.svg";
import "./LoginPage.scss";
import { Form, Input, Button, Checkbox, Image, Typography, theme } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useSignInForm } from "./model";

const SignInForm = () => {
    const { formRef, error, loading, onSubmit } = useSignInForm();
    const { token } = theme.useToken();
    return (
        <div>
            <Form
                name="normal_username"
                className="username-form signin__form"
                onFinish={onSubmit}
                initialValues={{ remember: true }}
                ref={formRef}
            >
                <div className="signin__image-container">
                    <div className="signin__image-frame" style={{ background: token.colorPrimary }}>
                        <Image preview={false} src={logo} alt="logo" />
                    </div>
                    <div className="signin__image-description">Сотрудники</div>
                </div>
                <Typography.Title level={2} style={{ textAlign: "center" }}>
                    Вход
                </Typography.Title>

                <Form.Item
                    name="username"
                    validateStatus={error ? "error" : undefined}
                    rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
                    help={error ? "Проверьте правильность данных" : undefined}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="E-mail"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    validateStatus={error ? "error" : undefined}
                    rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
                    help={error ? "Проверьте правильность данных" : undefined}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Пароль"
                        // value={isPassAvailable ? password : ""}
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Запомнить меня</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="username-form-button"
                        loading={loading}
                    >
                        Войти
                    </Button>
                    {/* Или{" "}
                    <a onClick={() => history.push("/sign-up")}>
                        зарегистрироваться
                    </a> */}
                </Form.Item>
            </Form>
        </div>
    );
};

export default SignInForm;
