import { EditOutlined } from "@ant-design/icons";
import { useDeviceTableActions } from "../model";
import UploadAdditionalFilesButton from "./UploadAdditionalFilesButton";
import { Button } from "antd";

interface IDeviceTableActions {
    value: TTableDataGroupedSubType;
    setIsOpen: (item: boolean) => void;
}

const DeviceTableActions = ({ value, setIsOpen }: IDeviceTableActions) => {
    const { onEditRowClick } = useDeviceTableActions(value);
    return (
        <div>
            <Button
                icon={<EditOutlined style={{ color: "rgb(43, 161, 165)" }} type="" />}
                onClick={onEditRowClick}
                style={{ marginBottom: 8, width: 220 }}
            >
                Изменить версию
            </Button>
            <UploadAdditionalFilesButton row={value} setIsOpen={setIsOpen} />
        </div>
    );
};

export default DeviceTableActions;
