import { firmwareDeviceApi, firmwareDeviceModel } from "@entities/FirmwareDevice";
import { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useFirmwareDeviceType = (
    setDeviceSubTypeOptions: (item: DefaultOptionType[]) => void
) => {
    const deviceType = useSelector(firmwareDeviceModel.selectCurrentDeviceType);
    const dispatch = useDispatch();
    const [deviceTypeOptions, setDeviceTypeOptions] = useState<DefaultOptionType[]>([]);

    useEffect(() => {
        firmwareDeviceModel.setCurrentDeviceType(deviceType);
        firmwareDeviceApi.getDevicesTypes().then(({ data: { data } }) => {
            const currentDeviceTypeOption = data.map((deviceType: any) => ({
                label: deviceType.name,
                value: deviceType.id,
            }));
            setDeviceTypeOptions(currentDeviceTypeOption);
        });
        const lastChoosenDeviceType = localStorage.getItem("deviceType");
        if (lastChoosenDeviceType)
            dispatch(firmwareDeviceModel.setCurrentDeviceType(lastChoosenDeviceType));
    }, []);

    const onSelectDeviceType = async (value: string) => {
        dispatch(firmwareDeviceModel.setCurrentDeviceType(value));
        if (localStorage.getItem("deviceType") !== value) {
            dispatch(firmwareDeviceModel.setCurrentDeviceSubType(null));
            dispatch(firmwareDeviceModel.setCurrentVersion(null));
            localStorage.setItem("deviceSubType", "");
            localStorage.setItem("deviceVersion", "");
        }
        localStorage.setItem("deviceType", value);
        await firmwareDeviceApi
            .getDevicesSubTypes(value)
            .then(({ data: { data } }) => {
                const deviceSubTypesOptions = Object.entries(data[value]).map(([key, value]) => ({
                    label: key,
                    value: key,
                    item: value,
                }));
                setDeviceSubTypeOptions(deviceSubTypesOptions);
            })
            .catch((e: any) => {
                console.log(e);
            });
    };

    return {
        deviceType,
        deviceTypeOptions,
        onSelectDeviceType,
    };
};
