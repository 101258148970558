export const selectVersions = (state: RootState): TVersions => state.devicesSlice.versions;
export const selectGroupedVersions = (state: RootState): TGroupedVersion[] =>
    state.devicesSlice.groupedVersions;
export const selectAdditionalFiles = (state: RootState): TAdditionalFiles =>
    state.devicesSlice.additionalFiles;
export const selectError = (state: RootState): any => state.devicesSlice.error;
export const selectEditingSubType = (state: RootState): TTableDataGroupedSubType | null =>
    state.devicesSlice.editingSubType;
export const selectDevicesSliceIsLoading = (state: RootState): boolean =>
    state.devicesSlice.isLoading;
export const selectEditingAdditionalFiles = (state: RootState): TEditingAdditionalFiles | null =>
    state.devicesSlice.editingAdditionalFiles;
