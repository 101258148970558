import { useTerminalEmits } from "@shared/ui/Terminal";

export const useEspLoaderTerminal = () => {
    const terminal = useTerminalEmits("firmware");
    const espLoaderTerminal = {
        clean: function () {
            // terminal.setTerminalText({ text: [] });
        },
        write: function (message: string) {
            terminal.updateLatestMessageEmit(message);
        },
        writeLine: function (message: string) {
            terminal.addInfoMessageEmit(message);
        },
    };
    return espLoaderTerminal;
};
