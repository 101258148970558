import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { DefaultOptionType } from "antd/es/select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useFirmwareDeviceSubType = () => {
    const deviceSubType = useSelector(firmwareDeviceModel.selectCurrentDeviceSubType);
    const dispatch = useDispatch();
    // const lastChoosenSubType = localStorage.getItem("deviceSubType");

    useEffect(() => {
        const lastChoosenDeviceSubType = localStorage.getItem("deviceSubType");
        if (lastChoosenDeviceSubType)
            dispatch(firmwareDeviceModel.setCurrentDeviceSubType(lastChoosenDeviceSubType));
        const lastChoosenDeviceVersion = localStorage.getItem("deviceVersion");
        if (lastChoosenDeviceVersion)
            dispatch(firmwareDeviceModel.setCurrentVersion(JSON.parse(lastChoosenDeviceVersion)));
    }, []);

    const onSelectDeviceSubType = (value: string, item: DefaultOptionType) => {
        dispatch(firmwareDeviceModel.setCurrentVersion(null));
        dispatch(firmwareDeviceModel.setCurrentDeviceSubType(value));
        localStorage.setItem("deviceSubType", value);
        localStorage.setItem(
            "deviceVersion",
            JSON.stringify({
                version: item.item[0].version,
                id: item.item[0].id,
            })
        );
        dispatch(
            firmwareDeviceModel.setCurrentVersion({
                version: item.item[0].version,
                id: item.item[0].id,
            })
        );
    };

    return {
        deviceSubType,
        onSelectDeviceSubType,
    };
};
