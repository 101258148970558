import { $api } from "@shared/api/api";
export const getVersions = () => $api.get<TVersions>("https://old.devices.vakio.ru/versions/");

export const updateVersion = (updatedSubType: TTableDataGroupedSubType) =>
    $api.post<TTableDataGroupedSubType>(
        "https://old.devices.vakio.ru/versions/update",
        updatedSubType
    );

export const deleteVersionFile = (name: string) =>
    $api.delete(`https://old.devices.vakio.ru/versions/delete/${name}`);

export const getAdditionalFiles = () =>
    $api.get<TResponse<TAdditionalFiles>>("/api/firmware/additional");

export const uploadAdditionalFiles = (formData: FormData) =>
    $api.post("/api/firmware/additional/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
