import { DeviceChangeVersionModal, UploadAdditionalFilesModal } from "@features/Devices";

interface IDevicesModals {
    isOpen: boolean;
}

const DevicesModals = ({ isOpen }: IDevicesModals) => {
    return (
        <>
            <UploadAdditionalFilesModal isOpen={isOpen} />
            <DeviceChangeVersionModal />
        </>
    );
};

export default DevicesModals;
