import { emitter } from "@shared/emitter";
import {
    ADD_MESSAGE_EMIT,
    DELETE_MESSAGE_EMIT,
    LOADING_STATE_EMIT,
    UPDATE_LASTERS_MESSAGE_EMIT,
    UPDATE_MESSAGE_EMIT,
} from "./constants";
import { getNormalizedTime } from "@shared/lib/date";

export const useTerminalEmits = (terminalName: string) => {
    const addMessageEmit = (message: string, status: TTerminalMessateStatus) => {
        const date = new Date();
        const time = getNormalizedTime(date.toISOString());
        emitter.emit(terminalName + ADD_MESSAGE_EMIT, {
            message,
            status,
            time,
        });
    };
    const addInfoMessageEmit = (message: string) => {
        addMessageEmit(message, "info");
    };
    const addErrorMessageEmit = (message: string) => {
        addMessageEmit(message, "error");
    };
    const addSuccessMessageEmit = (message: string) => {
        addMessageEmit(message, "success");
    };

    const updateMessageEmit = (newMessage: TTerminalMessage) => {
        emitter.emit(terminalName + UPDATE_MESSAGE_EMIT, newMessage);
    };
    const updateLatestMessageEmit = (message: string) => {
        emitter.emit(terminalName + UPDATE_LASTERS_MESSAGE_EMIT, message);
    };
    const deleteMessageEmit = (messageId: number) => {
        emitter.emit(terminalName + DELETE_MESSAGE_EMIT, messageId);
    };
    const setLoadingStateEmit = (state: boolean) => {
        emitter.emit(terminalName + LOADING_STATE_EMIT, state);
    };

    return {
        addMessageEmit,
        addSuccessMessageEmit,
        updateLatestMessageEmit,
        addInfoMessageEmit,
        addErrorMessageEmit,
        updateMessageEmit,
        deleteMessageEmit,
        setLoadingStateEmit,
    };
};
