import { ThemeConfig } from "antd";

export const theme: ThemeConfig = {
    token: {
        borderRadius: 2,
        colorPrimary: "#2BA1A5",
        colorInfo: "#2BA1A5",
        // colorPrimaryText: "red",
        // colorPrimaryBorder: "red",
        colorLink: "#2BA1A5",
        // colorPrimaryActive: "#81A9B0",
    },
};
