import { UploadOutlined } from "@ant-design/icons";
import { Button, Spin, Upload } from "antd";
import { useUploadVersion } from "../model";

const UploadVersion = () => {
    const { props, isLoading, onChange } = useUploadVersion();
    return (
        <>
            <Upload {...props} onChange={onChange} maxCount={1} showUploadList={false}>
                <Button style={{ marginBottom: 16 }} icon={<UploadOutlined />} loading={isLoading}>
                    Загрузить прошивку
                </Button>
            </Upload>
            {isLoading && <Spin />}
        </>
    );
};

export default UploadVersion;
