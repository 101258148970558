import { useRef } from "react";
import { AlwaysScrollToBottom } from "../../AlwaysScrollToBottom";
import { getMessageStatusColor } from "../lib";
import { useTerminal } from "../model";
import "./Terminal.scss";

interface ITerminal {
    terminalName: string;
}

const Terminal = ({ terminalName }: ITerminal) => {
    const { messages } = useTerminal(terminalName);
    const terminalContainer = useRef<HTMLDivElement | null>(null);
    return (
        <div className="terminal">
            <div className="terminal__container" ref={terminalContainer}>
                {messages.map(({ message, time, status }, index) => {
                    return (
                        <span key={index} className="text">
                            <span style={{ color: "#BBEE78" }}>{time}: </span>
                            <span style={{ color: getMessageStatusColor(status) }}>{message}</span>
                        </span>
                    );
                })}
                <AlwaysScrollToBottom />
            </div>
        </div>
    );
};

export default Terminal;
