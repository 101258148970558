import { Layout } from "antd";
import { useHeader } from "./model";
import "./Header.scss";
import React from "react";
interface IHeader {
    children: React.ReactNode;
    collapse: boolean;
}

const Header = ({ children, collapse }: IHeader) => {
    const { layout } = useHeader();
    return (
        <Layout.Header
            className="header"
            style={{
                overflow: !collapse ? "hidden" : "",
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
            }}
        >
            {children}
            <div className="header__title-container">
                <div className="header__title-container__title">{layout?.title}</div>
                {layout?.subtitle && (
                    <div className="header__title-container__subtitle">{layout?.subtitle}</div>
                )}
            </div>
        </Layout.Header>
    );
};

export default Header;
