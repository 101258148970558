import { Button, Popconfirm } from "antd";
import { useFlashAttemptsActions } from "../model";
import { CSSProperties } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

interface IFlashAttemptsActions {
    selectedIds: React.Key[];
    row: TFlashAttemptData;
    setChanged: (item: boolean) => void;
}

const FlashAttemptsActions = ({ selectedIds, row, setChanged }: IFlashAttemptsActions) => {
    const { onDeleteRowClick, onEditRowClick } = useFlashAttemptsActions(row, setChanged);
    const buttonStyle: CSSProperties = { margin: 0, width: "fit-content", padding: 0 };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
                icon={<EditOutlined />}
                type="link"
                style={{ ...buttonStyle, marginRight: 16 }}
                onClick={onEditRowClick}
            />
            <Popconfirm
                title="Удалить строку?"
                okText="Удалить"
                onConfirm={onDeleteRowClick}
                cancelText="Отмена"
            >
                <Button
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    type="link"
                    style={{ ...buttonStyle, color: "red" }}
                />
            </Popconfirm>
        </div>
    );
};

export default FlashAttemptsActions;
