import { useDispatch, useSelector } from "react-redux";
import { devicesModel } from "..";
import { useEffect } from "react";
import { loadAdditionalFilesThunk } from "./DevicesThunk";

export const useLoadAdditionalFiles = (preload?: boolean) => {
    const additionalFiles = useSelector(devicesModel.selectAdditionalFiles);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadAdditionalFiles();
        }
    }, []);

    const loadAdditionalFiles = () => {
        dispatch(loadAdditionalFilesThunk());
    };

    return {
        additionalFiles,
        loadAdditionalFiles,
    };
};
