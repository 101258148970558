import { UploadOutlined } from "@ant-design/icons";
import { useUploadAdditionalFile } from "@features/Devices/model/UseUploadAdditionalFile";
import { Button, Upload } from "antd";

const UploadAdditionalFilesItemUni = ({
    isDisableUploadButton,
}: {
    isDisableUploadButton: boolean;
}) => {
    const { props, isLoading, onChange } = useUploadAdditionalFile();
    return (
        <div style={{ marginBottom: 8, width: "100%" }}>
            {/* <Tooltip
                title="Загружено максимальное количество файлов"
                open={isDisableUploadButton}
                placement="topRight"
            > */}
            <Upload {...props} style={{ width: "100%" }} maxCount={3} onChange={onChange}>
                <Button
                    disabled={isDisableUploadButton}
                    style={{ width: "100%" }}
                    icon={<UploadOutlined />}
                    loading={isLoading}
                >
                    Выбрать вспомогательные файлы
                </Button>
            </Upload>
            {/* </Tooltip> */}
        </div>
    );
};

export default UploadAdditionalFilesItemUni;
